<template>
  <component
    :is="base ? BaseCollapse : VfAccordion"
    v-show="auth.loggedIn && user.details"
    v-model="opened"
    :class-toggle="classToggle"
  >
    <template #toggle="{ expanded }">
      <div class="flex items-center pb-3 pt-4 between" data-test-id="vf-accordion-toggle">
        <p class="text-md fw-light">
          {{ loyaltyMessages.rewardsGreeting }}
        </p>
        <vf-icon :dir="expanded ? 'up' : 'down'" name="chevron" size="lg" />
      </div>
    </template>
    <div class="flex pb-4">
      <vf-icon class="mr-4" size="xl" :src="logo" />
      <div>
        <p class="pb-3 fw-medium lh-1">
          {{ loyaltyMessages.title }}
        </p>
        <p class="ws-pre-wrap">
          {{ loyaltyMessages.text }}
        </p>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { BaseCollapse, VfAccordion } from '#components'

defineProps<{
  base?: boolean
  classToggle?: string
}>()

const user = useUserStore()
const auth = useAuthStore()
const loyalty = useLoyaltyStore()
const { getLoyaltyMessages } = useLoyalty()
const { logo } = useAppConfig().components.vf.loyalty

whenever(() => auth.loggedIn, () => loyalty.getRewards(), { immediate: true })

const loyaltyMessages = computed(() => getLoyaltyMessages())

const opened = defineModel({ default: true })
</script>
